
import React from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/material/styles';
import SessionTableDetails from './SessionTableDetails';
import { Modal, Fade, Backdrop, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Tab, Tabs } from '@mui/material';

const PREFIX = 'SegmentInfoPopup';

const classes = {
    modal: `${PREFIX}-modal`,
    paper: `${PREFIX}-paper`,
    header: `${PREFIX}-header`,
    infogroup: `${PREFIX}-infogroup`,
    headerText: `${PREFIX}-headerText`,
    marker: `${PREFIX}-marker`,
    sidemargin: `${PREFIX}-sidemargin`
};

const StyledModal = styled(Modal)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    maxWidth: "1200px",
    margin: "auto",
    [`& .${classes.paper}`]: {
        display: 'flex',
        flexDirection: 'column',
        outline: 'none',
        width: '100%',
        minWidth: '300px',
        // padding: '15px 0 0 0',
        padding: '1rem',
        // maxHeight: '98%'
        height: '90%',
        overflowY: 'scroll'
    },
    [`& .${classes.header}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
        padding: '0 15px 0 15px',
        marginBottom: '6px'
    },
    [`& .${classes.infogroup}`]: {
        margin: '6px auto 6px 1em',
        paddingTop: '10px'
    },
    [`& .${classes.headerText}`]: {
        margin: 'auto 0 auto 0'
    },
    [`& .${classes.marker}`]: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    [`& .${classes.sidemargin}`]: {
        margin: '0 3px 0 3px'
    },
});

export const SegmentInfoPopup = React.memo(({ id, handleClose, respondentData }) => {


    return (
        <StyledModal
            open={!!id}
            onClose={handleClose}
            // closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 250,
                    sx: { zIndex: -1 }
                }
            }}
            disablePortal
        >
            <Fade in={!!id}>
                <Paper className={classes.paper}>
                    <SessionTableDetails {...respondentData} handleClose={handleClose}/>
                </Paper>
            </Fade>
        </StyledModal>
    );

})