import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Chip, Divider, Typography, Badge } from '@mui/material';
import ScheduleIcon from '@mui/icons-material/Schedule';
import VideocamIcon from '@mui/icons-material/Videocam';
import YouTubeIcon from '@mui/icons-material/YouTube';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import SecurityIcon from '@mui/icons-material/Security';
import AssignmentIcon from '@mui/icons-material/Assignment';
import GroupIcon from '@mui/icons-material/Group';
import CancelIcon from '@mui/icons-material/Cancel';
// import TheatersIcon from '@mui/icons-material/Theaters';
import MovieIcon from '@mui/icons-material/Movie';
import moment from 'moment'

const PREFIX = 'SessionTableDetails';

const classes = {
    root: `${PREFIX}-root`,
    category: `${PREFIX}-category`,
    info: `${PREFIX}-info`,
    segment: `${PREFIX}-segment`,
    question: `${PREFIX}-question`,
    answers: `${PREFIX}-answers`,
    answer: `${PREFIX}-answer`,
    goal: `${PREFIX}-goal`,
    box: `${PREFIX}-box`,
    group: `${PREFIX}-group`,
    header: `${PREFIX}-header`,
    section: `${PREFIX}-section`,
    infogroup: `${PREFIX}-infogroup`,
    marker: `${PREFIX}-marker`,
    sidemargin: `${PREFIX}-sidemargin`,
    label: `${PREFIX}-label`,
    top: `${PREFIX}-top`,
    cancelIcon: `${PREFIX}-cancelIcon`,
    titlegroup: `${PREFIX}-titlegroup`,
    chip: `${PREFIX}-chip`,
    pfm: `${PREFIX}-pfm`,
    textIcon: `${PREFIX}-textIcon`,
    textRow: `${PREFIX}-textRow`,
    youtube: `${PREFIX}-youtube`
};

const Root = styled('div')({
    // style for <Root> component
    display: 'flex',
    flexDirection: 'column',
    padding: '2em',
    paddingTop: '1em',
    [`& .${classes.category}`]: {
        // flexBasis: '20%'
        textAlign: 'right',
        marginRight: '6px'
    },
    [`& .${classes.info}`]: {
        // display: 'flex',
        // flexDirection: 'row',
        // justifyContent: 'space-evenly',
        width: '100%'
    },
    [`& .${classes.segment}`]: {
        maxWidth: '400px',
        minWidth: '300px',
        width: '40%',
        // marginLeft: 'auto',
        margin: '12px 24px 12px 24px',
        padding: '6px',
        border: '2px solid #eee',
        borderRadius: '8px',
    },
    [`& .${classes.question}`]: {
        display: 'flex',
        flexDirection: 'row',
    },
    [`& .${classes.answers}`]: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    [`& .${classes.answer}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    [`& .${classes.goal}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
    },
    [`& .${classes.box}`]: {
        margin: '6px'
    },
    [`& .${classes.group}`]: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly'
    },
    [`& .${classes.header}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '3px',
        borderBottom: '2px solid black'
    },
    [`& .${classes.section}`]: {
        marginBottom: '6px'
    },
    [`& .${classes.infogroup}`]: {
        margin: '6px auto 6px 1em'
    },
    [`& .${classes.marker}`]: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    [`& .${classes.sidemargin}`]: {
        margin: '0 3px 0 3px'
    },
    [`& .${classes.label}`]: {
        textTransform: 'uppercase',
        fontWeight: 'bold'
    },
    [`& .${classes.cancelIcon}`]: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    [`& .${classes.top}`]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline'
    },
    [`& .${classes.titlegroup}`]: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '8px'
    },
    [`& .${classes.chip}`]: {
        margin: '1px',
        fontSize: '0.6125rem'
    },
    [`& .${classes.pfm}`]: {
        margin: '1px',
        backgroundColor: '#6C54A4'
    },
    [`& .${classes.textIcon}`]: {
        marginRight: '0.5em'
    },
    [`& .${classes.textRow}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    [`& .${classes.youtube}`]: {
        margin: '1px',
        backgroundColor: '#e52d27'//'#b31217'
    }
});

// const LabelBadge =  => ({
//     badge: {
//         transform: 'scale(1) translate(110%, -50%)'
//     },
// }))(Badge);

const timeStringFromMS = (time) => {
    const hours = time / (3600000)
    const remainder = time % (3600000)
    return `${Math.floor(hours)}`.padStart(2, '0') + ":" + `${Math.floor((remainder) / (60000))}`.padStart(2, '0') + ":" + `${Math.ceil((remainder) % (60000) / (1000))}`.padStart(2, '0')
}

// Session time to local time based on user browser.
const sessionTime = (time) => {
    const localTimezone = moment.tz.guess()
    return moment.tz(time, localTimezone).format('MM/D/YYYY, h:mm a z')
}

const SessionTableDetails = React.memo(({ id, sessionName, scheduledStartTime, staffArrivalTime, guestCheckInTime, clientArrivalTime, seaJobCode, team, client, isPfmScreening, pfmScreeningId, videoPlatform, isLiveSession, videoTitle, hasZoomMeeting, zoomHostId, zoomMeetingId, hasSurvey, surveyId, surveyType, videoMarkers, audienceSegments, virtualSegments, segmentGoals, qualifyingSegment, isDialSession, scoreBase, dialStyle, sessionBranding, gaugeStyle, numberOfMeters, sliderKnob, sliderKnobColor, videoId, playerId, sessionStartTime, sessionEndTime, videoLength, started, liveVideo, liveStartTime, manualStartTime, include2fa, allowMobileDevices, allowedBrowsers, allowedCountries, subGeoRestriction, watermarkOpacity, preventLateEntry, lateEntryTimeLimit, limitDisconnect, disconnectCountLimit, disconnectTimeLimit, chimeChatChannels, created, createdBy, lastUpdated, lastUpdatedBy, clientMagicLink, magicLinkUrl, requirePasscode, magicLinkPasscode, requireEmailValidation, multiSection, internalVideoName, dialMode, symbolOptions, includeTuneOut, inactiveReminder, handleClose }) => {


    const audienceSegmentsObj = useMemo(() => {
        let segmentsObj = audienceSegments || []
        let virtualSegsObj = virtualSegments || []
        let goalsObj = segmentGoals || {}
        // have to do JSON.parse(JSON.stringify()) to make obj extensible   
        return JSON.parse(JSON.stringify([...segmentsObj, ...virtualSegsObj])).map(segment => {
            if (goalsObj[segment.questionKey]) {
                Object.entries(goalsObj[segment.questionKey]).forEach(([answerKey, goal]) => {
                    const answerIndex = segment.answers.findIndex(x => x.answer === answerKey)
                    if (answerIndex >= 0) segment.answers[answerIndex].goal = ((!!goal && typeof goal === 'object') ? { ...goal } : goal)
                })
            }
            return segment
        })
    }, [audienceSegments, virtualSegments, segmentGoals])

    return (
        <Root>
            <div className={classes.cancelIcon}><CancelIcon sx={{cursor: 'pointer', '&:hover': {color: 'red'}}} onClick={handleClose}/></div>
            <div className={classes.top}>
                <div className={classes.titlegroup}>
                    <Typography variant="h4">{sessionName}</Typography>
                    <div className={classes.textRow}>
                        {videoPlatform === 'YouTube' ? <YouTubeIcon className={classes.textIcon} /> : <VideocamIcon className={classes.textIcon} />}
                        <Typography variant="h6">{videoTitle}</Typography>
                    </div>
                    <div className={classes.textRow}>
                        <ScheduleIcon className={classes.textIcon} />
                        <Typography variant="h5">{sessionTime(scheduledStartTime)}</Typography>
                    </div>
                    {/* <div className={classes.textRow}>
                        <Typography variant="overline">STAFF ARRIVAL TIME:</Typography>
                        <Typography variant="overline">{new Date(staffArrivalTime).toLocaleDateString()}&nbsp;{new Date(staffArrivalTime).toLocaleTimeString()}</Typography>
                    </div>
                    <div className={classes.textRow}>
                        <Typography variant="overline">GUEST CHECK-IN TIME:</Typography>
                        <Typography variant="overline">{new Date(guestCheckInTime).toLocaleDateString()}&nbsp;{new Date(guestCheckInTime).toLocaleTimeString()}</Typography>
                    </div> */}
                    <div className={classes.textRow}>
                        <Typography variant="overline">STAFF - {sessionTime(staffArrivalTime)}</Typography>
                        <Typography variant="h5">&nbsp;|&nbsp;</Typography>
                        <Typography variant="overline">GUESTS - {sessionTime(guestCheckInTime)}</Typography>
                        <Typography variant="h5">&nbsp;|&nbsp;</Typography>
                        <Typography variant="overline">CLIENTS - {sessionTime(clientArrivalTime)}</Typography>
                    </div>
                </div>
                <div className={classes.titlegroup}>
                    {isPfmScreening && <Chip color="primary" className={classes.pfm} label={`PFM SCREENING #${pfmScreeningId}`} size="small"></Chip>}
                    {videoPlatform === 'YouTube' && <Chip color="primary" className={classes.youtube} label={`YOUTUBE${isLiveSession && ' LIVE'}`} size="small"></Chip>}
                    <Chip className={classes.chip} label={`ID ${id} - ${team}`} size="small" variant="outlined"></Chip>
                    {/* <Chip className={classes.chip} label={`JOB ${seaJobCode}`} size="small" variant="outlined" color="primary"></Chip> */}
                    {/* <Chip className={classes.chip} label={`TEAM ${team}`} size="small" variant="outlined" color="primary"></Chip> */}
                    <Chip className={classes.chip} label={`${client}`} size="small" variant="outlined" color="secondary"></Chip>
                </div>
            </div>

            {hasZoomMeeting && <div className={classes.info}>

                <div className={classes.box}>
                    <div className={classes.header}>
                        <OndemandVideoIcon fontSize="small" className={classes.textIcon} />
                        <Typography>Zoom Meeting Info</Typography>
                    </div>
                    <div className={classes.infogroup}>
                        {zoomHostId && <Typography>Zoom Host ID: {zoomHostId}</Typography>}
                        {zoomMeetingId && <Typography>Zoom Meeting ID: {zoomMeetingId}</Typography>}
                    </div>
                </div>
            </div>}

            {videoId && <div className={classes.info}>

                <div className={classes.box}>
                    <div className={classes.header}>
                        <OndemandVideoIcon fontSize="small" className={classes.textIcon} />
                        <Typography>Session Video Info</Typography>
                    </div>
                    <div className={classes.infogroup}>
                        <Typography>Content Mode: {multiSection ? 'Multi' : 'Single'}</Typography>
                        {multiSection ? <>
                            {videoId && <Typography>Brightcove Playlist ID: {videoId}</Typography>}
                            <Typography>Content Count: {internalVideoName.length}</Typography>
                        </> : <>
                            {sessionStartTime && <Typography>Video Start Time: {moment(liveStartTime || sessionStartTime).format('MMMM Do YYYY, h:mm a')}</Typography>}
                            {videoLength && <Typography>Video Length: {timeStringFromMS(videoLength)}</Typography>}
                            {videoId && <Typography>Brightcove Video ID: {videoId}</Typography>}
                            {playerId && <Typography>Brightcove Player ID: {playerId}</Typography>}
                        </>}
                        <Typography>Video Player Branding: {sessionBranding}</Typography>
                    </div>
                </div>
            </div>}

            {hasSurvey && <div className={classes.info}>
                <div className={classes.box}>
                    <div className={classes.header}>
                        <AssignmentIcon fontSize="small" className={classes.textIcon} />
                        <Typography>Survey Settings</Typography>
                    </div>
                    <div className={classes.infogroup}>
                        <Typography>Survey Type: {surveyType}</Typography>
                        <Typography>Survey ID: {surveyId}</Typography>
                    </div>
                </div>
            </div>}

            <div className={classes.info}>
                {!!(videoMarkers && videoMarkers.length) && <div className={classes.box}>
                    <div className={classes.header}>
                        <MovieIcon fontSize="small" className={classes.textIcon} />
                        <Typography>Video Markers</Typography>
                    </div>
                    <div className={classes.infogroup}>
                        {videoMarkers.map((marker, i) =>
                            <div className={classes.marker} key={`marker-display${i}`}>
                                <Typography variant="subtitle2">{`${marker.markerHour}`.padStart(2, '0')}:{`${marker.markerMinute}`.padStart(2, '0')}:{`${marker.markerSecond}`.padStart(2, '0')}</Typography>
                                <Typography className={classes.sidemargin} variant="subtitle1">&mdash;</Typography>
                                <Typography variant="subtitle1">{marker.markerName}</Typography>
                            </div>
                        )}
                    </div>
                </div>}
            </div>

            {clientMagicLink && <div className={classes.info}>
                <div className={classes.box}>
                    <div className={classes.header}>
                        <GroupIcon fontSize="small" className={classes.textIcon} />
                        <Typography>Client Access</Typography>
                    </div>
                    <div className={classes.infogroup}>
                        <Typography>Client Shared Link: {magicLinkUrl}</Typography>
                        {console.log('requirePasscode:', requirePasscode, magicLinkPasscode)}
                        <Typography>Passcode: {requirePasscode ? magicLinkPasscode : 'N/A'}</Typography>
                        <Typography>Require Email Validation: {requireEmailValidation ? 'YES' : 'NO'}</Typography>
                    </div>
                </div>
            </div>}

            <div className={classes.info}>
                <div className={classes.box}>
                    <div className={classes.header}>
                        <SecurityIcon fontSize="small" className={classes.textIcon} />
                        <Typography>Security Settings</Typography>
                    </div>
                    <div className={classes.infogroup}>
                        <Typography>Require 2-Factor Authentication: {include2fa ? 'YES' : 'NO'}</Typography>
                        <Typography>Allow Mobile Devices: {allowMobileDevices ? 'YES' : 'NO'}</Typography>
                        <Typography>Allowed Browsers: {allowedBrowsers.join(', ')}</Typography>
                        <Typography>Watermark Opacity: {watermarkOpacity ? `${watermarkOpacity}%` : '15%'}</Typography>
                        <Typography>Prevent Late Entry: {preventLateEntry ? 'YES' : 'NO'}</Typography>
                        {preventLateEntry && <>
                            <Typography>Late Entry Time Limit: {lateEntryTimeLimit} minutes</Typography>
                        </>}
                        <Typography>Limit Disconnect: {limitDisconnect ? 'YES' : 'NO'}</Typography>
                        {limitDisconnect && <>
                            <Typography>Disconnect Time Limit: {disconnectTimeLimit} minutes</Typography>
                            <Typography>Disconnect Count Limit: {disconnectCountLimit}</Typography>
                        </>}
                    </div>
                </div>
            </div>


            <div className={classes.info}>
                {!!(audienceSegmentsObj && audienceSegmentsObj.length) && <div className={classes.box}>
                    <div className={classes.header}>
                        <QuestionAnswerIcon fontSize="small" className={classes.textIcon} />
                        <Typography>Audience Segments</Typography>
                    </div>
                    <div className={classes.group}>
                        {audienceSegmentsObj.map((segment, i) =>
                            <Badge key={`segment-display${i}`} className={classes.segment} badgeContent={segment.multiple ? 'MULTI-SELECT' : segment.matrix ? 'MATRIX' : 'VIRTUAL'} color="primary" invisible={!(segment.multiple || segment.matrix || segment.dataKey === 'segments')}>
                                <div className={classes.info}>
                                    <Typography variant="subtitle2" className={classes.label}>
                                        <span>{segment.questionKey}</span>
                                    </Typography>
                                    <Divider />
                                    <div className={classes.question}>
                                        <Typography className={classes.category} variant="subtitle1">Question:</Typography>
                                        <Typography variant="subtitle1">{segment.question}</Typography>
                                    </div>
                                    {segment.matrix && <div className={classes.question}>
                                        <Typography className={classes.category} variant="subtitle1">Options:</Typography>
                                        <div className={classes.answers}>
                                            {segment.matrixKeys.map((option, x) =>
                                                <div className={classes.answer} key={`segment-display${i}-option${x}`}>
                                                    <Typography variant="subtitle1">{option.option}</Typography>
                                                </div>
                                            )}
                                        </div>
                                    </div>}
                                    <div className={classes.question}>
                                        <Typography className={classes.category} variant="subtitle1">Answers:</Typography>
                                        <div className={classes.answers}>
                                            {segment.answers.map((answer, x) =>
                                                <div className={classes.answer} key={`segment-display${i}-answer${x}`}>
                                                    <Typography variant="subtitle1">{answer.answer}</Typography>
                                                    {!isNaN(answer.goal) && <div className={classes.goal}>
                                                        <Typography variant="caption">GOAL:</Typography>
                                                        <Typography variant="subtitle2">{answer.goal}%</Typography>
                                                    </div>}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Badge>
                        )}
                    </div>
                </div>}
            </div>

            <div className={classes.info}>


                {/* {!!(audienceSegments && audienceSegments.length) && <div className={classes.box}>
                    <Typography className={classes.header}>Audience Segments</Typography>
                    {audienceSegments.map((segment, i) =>
                        <div key={`segment-display${i}`}>
                            <Typography>{segment.question}</Typography>
                            {segment.answers.map((answer, x) =>
                                <Typography key={`segment-display${i}-answer${x}`}>{answer}</Typography>
                            )}
                        </div>
                    )}
                </div>} */}

                {isDialSession && <div className={classes.box}>
                    <div className={classes.header}>
                        <PhonelinkSetupIcon fontSize="small" className={classes.textIcon} />
                        <Typography>Real-Time Feedback Settings</Typography>
                    </div>
                    <div className={classes.infogroup}>
                        <Typography>Activity Type: {dialMode === 'symbol' ? 'Emoji' : dialMode === 'score' ? 'Dial' : 'Tune Out Only'}</Typography>
                        {dialMode === 'score' ? <><Typography>Max Score: {scoreBase}</Typography>
                            <Typography>Dial Style: {dialStyle}</Typography>
                            {dialStyle !== 'Embedded Slider' && <>
                                <Typography>Gauge Style: {gaugeStyle}</Typography>
                            </>}
                            <Typography>Number of Meters: {numberOfMeters}</Typography>
                            {dialStyle === 'Embedded Slider' && <>
                                <Typography>Slider Knob: {sliderKnob}</Typography>
                                <Typography style={{ display: 'flex', alignItems: 'baseline' }}>Slider Knob Color: <div style={{ height: '10px', width: '20px', marginLeft: '3px', backgroundColor: sliderKnobColor }}></div></Typography>
                            </>}</> : dialMode === 'symbol' ? <>
                                <Typography>Emoji Options: [<span style={{ fontFamily: 'Noto Color Emoji' }}>{symbolOptions.map(i => i.emoji).join(',')}</span>]</Typography>
                                <Typography>Tune Out: {includeTuneOut ? 'YES' : 'NO'}</Typography>
                                <Typography>Inactivity Reminder: {inactiveReminder ? 'YES' : 'NO'}</Typography>
                            </> : <>
                            <Typography></Typography>
                        </>}
                    </div>
                </div>}
            </div>

            <div className={classes.info}>
                <div className={classes.box}>
                    <Divider />
                    <div className={classes.titlegroup}>
                        <Typography variant="caption">Session Created at {moment(created).format('MMMM Do YYYY, h:mm a')} by {createdBy}</Typography>
                        <Typography variant="caption">Last Updated at {moment(lastUpdated).format('MMMM Do YYYY, h:mm a')} by {lastUpdatedBy}</Typography>
                        <Typography variant="caption">SE/ASI Job Code {seaJobCode}</Typography>
                    </div>
                </div>
            </div>

        </Root>
    );
})

export default (SessionTableDetails)