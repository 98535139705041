import React, { useCallback, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Button, Checkbox } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import StandardField from './StandardField';
import { useMemoCompare } from '../../../hooks/hooks'

const PREFIX = 'MultiRewardsField';

const classes = {
  root: `${PREFIX}-root`,
  checkbox: `${PREFIX}-checkbox`,
  checkboxPadding: `${PREFIX}-checkboxPadding`,
  checkboxContent: `${PREFIX}-checkboxContent`
};

const Root = styled('div')({
  width: '100%',
  // display: 'flex',
  // flexDirection: 'row',
  // padding: '6px',
  // margin: '0 8px 0 8px',
  // label: {
  //   display: 'flex',
  //   justifyContent: 'flex-end',
  //   alignItems: 'center',
  //   flexBasis: '20%',
  //   // textAlign: 'right',
  //   marginRight: '10px !important',
  //   padding: '5px',
  // },
  [`& .${classes.checkbox}`]: {
    flexBasis: '50%',
    // textAlign: 'right',
    // marginRight: '10px'
  },
  [`& .${classes.checkboxPadding}`]: {
    padding: '4px',
  },
  [`& .${classes.checkboxContent}`]: {
    display: 'flex',
    alignItems: 'center',
    width: 'max-content'
  },
});

const isEqual = require('lodash/isEqual');
const cloneDeep = require('lodash/cloneDeep');

const MultiRewardsField = React.memo(({ value = {}, splitGroup, denominations, denominationLabel, denominationValueKey, denominationDecimalKey, maxValue, decimals, label, fieldKey, required, conditional, conditionMet, disabled, disabledOptions, onChange, invalid, invalidate, ...restProps }) => {

  const valueRef = useRef();

  valueRef.current = value || {}

  // const channelNames = useMemoCompare(value ? value.map(x => x.displayName) : [], isEqual);

  const disabledPaymentTypes = useCallback((option, countries) => !(option?.countries || []).some(country => countries.includes(country)), []);

  const handleRecruiterSplit = useCallback((event) => {
    event.currentTarget.blur()
    const newRewards = cloneDeep(valueRef.current)
    newRewards.split = event.target.checked
    newRewards.splitKey = newRewards.split ? splitGroup.key : undefined
    onChange(event, newRewards)
  }, [onChange, splitGroup]);

  const handleRewardsChange = useCallback((newValue, index, key) => {
    const newRewards = cloneDeep(valueRef.current)
    newRewards[key] = newValue
    console.log('-----> rewards config change!', newValue, index, key, newRewards)
    onChange(null, newRewards)
  }, [onChange])

  return (
    <Root>
      {label && <Typography className={classes.label}>{label}</Typography>}
      {splitGroup?.key && <div className={classes.checkboxPadding}>
        <div className={classes.checkboxContent}>
          <Checkbox size="small" color="primary" checked={!!value?.split} disabled={disabled || splitGroup?.values?.length < 2} onChange={handleRecruiterSplit} />
          <Typography className={classes.checkLabel}>Split by {splitGroup.label}</Typography>
        </div>
      </div>}
      {denominations && denominations.map((denomination, i) =>
        <React.Fragment key={`rewards-fragment${i}`}>
          <StandardField
            {...restProps}
            type='rewards'
            fieldKey={`${fieldKey}-rewards${i}`}
            key={`${fieldKey}-rewards${i}`}
            label={(!!denomination && typeof denomination === 'object') ? typeof denominationLabel === 'function' ? denominationLabel(denomination) : denomination[denominationLabel] : denomination}
            currencySymbol={denomination.symbol}
            split={(!!value?.split && splitGroup?.values?.length > 1)}
            splitKeys={splitGroup?.values}
            value={value?.[(!!denomination && typeof denomination === 'object') ? denomination[denominationValueKey] : denomination]}
            maxValue={maxValue}
            decimals={(!!denomination && typeof denomination === 'object' && denominationDecimalKey) ? denomination[denominationDecimalKey] : (decimals || 2)}
            required={true}
            disabledOptions={disabledOptions || ((o) => disabledPaymentTypes(o, denomination['ISOalpha-2']))}
            // required={required || (conditional && conditionMet)}
            onChange={(e, v) => handleRewardsChange(v, i, (!!denomination && typeof denomination === 'object') ? denomination[denominationValueKey] : denomination)}
            invalidate={invalidate}
            disabled={disabled}
          />
        </React.Fragment>
      )}
    </Root>
  );
})

// MultiChatChannelField.defaultProps = {

// }

export default MultiRewardsField;
